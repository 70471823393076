<template>
    <div class="global-view-card" v-loading="pageLoad">
        <div class="global-detail-head">
            {{ title }}
        </div>
        <div class="global-detail-content" style="padding: 0 10px;">
            <el-form
                    ref="detailForm"
                    :model="detailForm"
                    :rules="detailRules"
                    class="global-detail-content"
                    label-width="auto"
                    label-suffix=":"
            >
                <el-form-item label="任务组" prop="jobGroup">
                    <el-input
                            v-model="detailForm.jobGroup"
                            placeholder="请输入任务组"
                    />
                </el-form-item>
                <el-form-item label="jobBean" prop="jobBean">
                    <el-input
                            v-model="detailForm.jobBean"
                            placeholder="请输入jobBean"
                    />
                </el-form-item>
                <el-form-item label="任务名称" prop="jobName">
                    <el-input
                            v-model="detailForm.jobName"
                            placeholder="请输入任务名称"
                    />
                </el-form-item>
                <el-form-item label="CRON" prop="jobBean">
                    <el-input
                            v-model="detailForm.jobCron"
                            placeholder="请输入CRON"
                            type="textarea"
                            :rows="6"
                            size="mini"
                    />
                </el-form-item>
                <el-form-item label="执行IP" prop="executeIp">
                    <el-input
                            v-model="detailForm.executeIp"
                            placeholder="请输入执行IP"
                            type="textarea"
                            :rows="6"
                            size="mini"
                    />
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="detailForm.status">
                        <el-option :value="true" label="启用"></el-option>
                        <el-option :value="false" label="禁用"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item class="global-detail-form-footer">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" :loading="saveLoad" @click="save">保 存</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import {
        getJobConfigDetail,addJobConfig,editJobConfig
    } from "@/api/business";


    export default {
        data() {
            return {
                pageLoad: false,
                title: "新增定时任务",
                id: null,
                detailForm: {
                    id: this.$route.query.id || null,
                    jobGroup: "",
                    jobBean: "",
                    jobName: "",
                    jobCron: "",
                    executeIp: "",
                    status: null,
                },
                notLimited: false,
                limitDisable: false,
                detailRules: {
                    jobGroup: [{required: true, message: "请输入任务组"}],
                    jobBean: [{required: true, message: "请输入jobBean"}],
                    jobName: [{required: true, message: "请输入任务名称"}],
                    jobCron: [{required: true, message: "请输入CRON"}],
                    executeIp: [{required: true, message: "请输入executeIp"}],
                },
                saveLoad: false,
            };
        },
        created() {
            if (this.$route.query.id) {
                this.title = '编辑定时任务';
                this.getJobConfigDetail();
            }
        },
        methods: {
            //新增定时任务
            addJobConfig(form) {
                this.saveLoad = true;
                addJobConfig(
                    form
                ).then(() => {
                    this.saveLoad = false;
                    this.cancel()
                    this.$message.success('添加成功')
                }).catch(() => {
                    this.saveLoad = false;
                })
            },

            //编辑定时任务
            editJobConfig(form) {
                this.saveLoad = true;
                editJobConfig(
                    form
                ).then(() => {
                    this.saveLoad = false;
                    this.cancel()
                    this.$message.success('更新成功')
                }).catch(() => {
                    this.saveLoad = false;
                })
            },

            getJobConfigDetail() {
                this.pageLoad = true;
                getJobConfigDetail({
                    id: this.detailForm.id
                }).then((res) => {
                    Object.assign(this.detailForm, res.data);
                    this.pageLoad = false;
                }).catch(() => {
                    this.pageLoad = false;
                });
            },
            save() {
                this.$refs.detailForm.validate((valid) => {
                    if (valid) {
                        if (this.detailForm.id) {
                            this.editJobConfig(this.detailForm)
                        } else {
                            this.addJobConfig(this.detailForm)
                        }
                    }
                });
            },
            cancel() {
                this.$router.push("/timerList");
            }
        },
    };
</script>

<style lang="scss">
    .banner-detail-dialog {
        .el-dialog__body {
            padding: 16px;
            .el-pagination {
                text-align: right;
                margin-top: 16px;
            }
        }
    }
</style>
